.buy-ticket .search-result {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.buy-ticket .search-result .buses-count {
    padding: 10px;
    background-color: #CBD5E1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.buy-ticket .search-result .buses-count .flex-gap-10 {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* Here */



.buy-ticket .search-result .content {
    width: 550px;
    padding: 5px;
}

.buy-ticket .search-result .content .back-btn {
    display: flex;
    text-decoration: none;
    color: black;
    align-items: center;
    gap: 5px;
    padding: 8px 8px 8px 15px;
    background-color: rgb(221, 221, 221);
    border-radius: 6px;
    width: fit-content;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.buy-ticket .search-result .content .back-btn div {
    font-size: 16px;
}


.buy-ticket .search-result .content .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.buy-ticket .search-result .content .buses {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.buy-ticket .search-result .content .bus {
    background-color: white;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    padding: 10px 20px;
    cursor: pointer;
}

.buy-ticket .search-result .content .bus-type {
    background-color: rgb(229, 249, 192);
    width: fit-content;
    font-size: 14px;
    margin-bottom: 6px;
    padding: 1px 10px;
    border-radius: 15px;
}

.buy-ticket .search-result .content .timeline {
    display: flex;
    justify-content: space-between;
}

.buy-ticket .search-result .content .timeline-item {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.buy-ticket .search-result .content .time {
    font-weight: bold;
}

.buy-ticket .search-result .content .travel-time {
    color: #6b6b6b;
}

.buy-ticket .search-result .content .price {
    font-weight: bold;
    font-size: 20px;
}

.buy-ticket .search-result .content .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.buy-ticket .search-result .content .info-details {
    display: flex;
    align-items: center;
    gap: 10px;
}

.buy-ticket .search-result .content .item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #5c5c5c;
}

.buy-ticket .search-result .content .nested-item {
    display: flex;
    align-items: center;
    gap: 6px;
}

.buy-ticket .search-result .content .seperator {
    width: 1px;
    height: 16px;
    background-color: rgb(181, 181, 181);
}

.buy-ticket .search-result .content .border-item {
    padding: 2px 10px;
    border: 1px solid grey;
    border-radius: 15px;
}

.buy-ticket .search-result .content .btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 6px 9px 12px;
    background-color: #e67c30;
    border: none;
    border-radius: 6px;
    color: white;

    &:hover {
        cursor: pointer;
        background-color: #ff7b06;
        opacity: 0.8;
    }
}

.buy-ticket .search-result .content div {
    font-size: 18px;
}

@media only screen and (max-width: 570px) {
    .buy-ticket .search-result .content {
        width: calc(100% - 10px);
    }
    .buy-ticket .search-result .content .bus {
        padding: 10px;
    }

    .buy-ticket .search-result .content .time {
        font-size: 16px;
    }

    .buy-ticket .search-result .content .city {
        font-size: 16px;
    }

    .buy-ticket .search-result .content .price {
        font-size: 18px;
    }

    .buy-ticket .search-result .content div {
        font-size: 14px;
    }
    .buy-ticket .search-result .content .btn {
        gap: 7px;
        padding: 5px 6px 5px 12px;
    }

}