.search-passenger {
    padding: 22px 10px 10px 10px;
    display: flex;
    gap: 10px;
}

.search-passenger .filters {
    background-color: #94A3B8;
    padding: 10px 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
}

.search-passenger .filters .title {
    font-size: 18px;
    font-weight: 600;
}

.search-passenger .filters .input {
    border: 1px solid #27272A;
    border-radius: 6px;
    background-color: #FFEDD5;
    padding: 8px 16px;
}

.search-passenger .filters .select {
    width: 220px;
    margin-top: 10px;
}

.search-passenger .filters .input-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 220px;
}

.search-passenger .filters .label {
    font-size: 14px;
    font-weight: 500;
}

.search-passenger .filters .btns-container {
    display: flex;
    justify-content: space-between;
}

.search-passenger .filters .btn {
    border: none;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;
    width: 100px;

    &:hover {
        opacity: 0.8;
    }
}

.search-passenger .filters .btn-1 {
    background-color: #CBD5E1;
}

.search-passenger .filters .btn-2 {
    background-color: #E67C30;
}

.search-passenger .section-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.search-passenger .section-2 .passengers-count {
    padding: 10px;
    background-color: #CBD5E1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.search-passenger .section-2 .passengers-count .flex-gap-10 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-passenger .section-2 .passengers-count .text-1 {
    font-size: 18px;
    font-weight: 600;
}

.search-passenger .section-2 .passengers-count .text-2 {
    font-size: 18px;
    font-weight: 400;
}

.search-passenger .section-2 .cards-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: stretch;
}

.search-passenger .section-2 .cards-container .card {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid rgba(228, 228, 231, 1);
    box-shadow: 0px 4px 6px -1px rgba(71, 85, 105, 0.1), 0px 2px 4px -2px rgba(71, 85, 105, 0.1);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.search-passenger .section-2 .cards-container .card .tags {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-passenger .section-2 .cards-container .card .tag {
    padding: 5px 8px;
    border-radius: 13px;
    color: rgba(39, 39, 42, 1);
    font-size: 14px;
    font-weight: 600;
}

.search-passenger .section-2 .cards-container .card .name {
    font-size: 18px;
    font-weight: 600;
    color: rgba(9, 9, 11, 1);
}

.search-passenger .section-2 .cards-container .card .timeline {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-passenger .section-2 .cards-container .card .timeline-detail {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-passenger .section-2 .cards-container .card .timeline-detail .text {
    color: rgba(9, 9, 11, 1);
    font-size: 14px;
    font-weight: 400;
}

.search-passenger .section-2 .cards-container .card .timeline-detail .seperator {
    border-bottom: 2px dashed rgba(230, 124, 48, 1);
    width: 90px;
    transform: translateY(2px);
}

.search-passenger .section-2 .cards-container .card .timeline-detail .seperator::before {
    content: '';
    position: absolute;
    background-color: rgba(230, 124, 48, 1);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translateY(-50%);
}

.search-passenger .section-2 .cards-container .card .timeline-detail .seperator::after {
    content: '';
    position: absolute;
    margin-left: 78px;
    background-color: rgba(230, 124, 48, 1);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translateY(-50%);
}

.search-passenger .section-2 .cards-container .card .info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.search-passenger .section-2 .cards-container .card .info .center {
    justify-content: center;
}

.search-passenger .section-2 .cards-container .card .btns-container {
    display: flex;
}

.search-passenger .section-2 .cards-container .card .btns-container .btn {
    border: none;
    padding: 5px 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
}

.search-passenger .section-2 .cards-container .card .btns-container .btn:first-child {
    border-radius: 3px 0 0 3px;
}

.search-passenger .section-2 .cards-container .card .btns-container .btn:last-child {
    border-radius: 0 3px 3px 0;
}


/* Global Styles */
.search-passenger .section-2 .bg-orange-300 {
    background-color: rgba(253, 186, 116, 1);
}

.search-passenger .section-2 .bg-green-400 {
    background-color: rgba(74, 222, 128, 1);
}

.search-passenger .section-2 .flex-row-gap-10 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-passenger .section-2 .flex-col-gap-10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.search-passenger .section-2 .align-end {
    align-items: flex-end;
}

.search-passenger .section-2 .flex-row-justify {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-passenger .section-2 .fs12-fw400 {
    font-size: 12px;
    font-weight: 400;
}

.search-passenger .section-2 .fs14-fw600 {
    font-size: 14px;
    font-weight: 600;
}

.search-passenger .section-2 .slate-600 {
    color: rgba(71, 85, 105, 1);
}

@media only screen and (min-width: 501px) and (max-width: 925px) {
    .search-passenger {
        flex-direction: column;
    }

    .search-passenger .filters {
        width: fit-content;
    }
}

@media only screen and (max-width: 500px) {
    .search-passenger {
        flex-direction: column;
    }

    .search-passenger .filters .input-container {
        width: 100%;
    }

    .search-passenger .filters .select {
        width: 100%;
    }

    .search-passenger .section-2 .cards-container .card {
        width: 100%;
    }
}