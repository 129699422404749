.create-route {
    padding: 22px 10px 10px 10px;
    display: flex;
    gap: 10px;
}

.create-route .form {
    background-color: #94A3B8;
    padding: 10px 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    max-height: calc(100vh - 52px);
    overflow-y: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}

.create-route .form .title {
    font-size: 18px;
    font-weight: 600;
}

.create-route .form .sub-title-container {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.create-route .form .closed {
    border-bottom: 1px solid #1E293B;
    padding: 10px;
}

.create-route .form .sub-title {
    font-size: 16px;
    font-weight: 600;
}

.create-route .form .icon {
    cursor: pointer;
}

.create-route .form .input {
    border: 1px solid #27272A;
    border-radius: 6px;
    background-color: #FFEDD5;
    padding: 8px 16px;
}

.create-route .form .select {
    width: 220px;
    margin-top: 10px;
}

.create-route .form .card {
    background-color: #CBD5E1;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.create-route .form .input-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 220px;
}

.create-route .form .label {
    font-size: 14px;
    font-weight: 500;
}

.create-route .form .btns-container {
    display: flex;
    justify-content: space-between;
}

.create-route .form .btn {
    border: none;
    border-radius: 6px;
    height: 40px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.create-route .form .open-btn {
    width: 110px;
}

.create-route .form .close-btn {
    width: 100px;
}

.create-route .form .btn-1 {
    background-color: #CBD5E1;
}

.create-route .form .btn-2 {
    background-color: #E67C30;
}

@media only screen and (min-width: 501px) and (max-width: 1205px) {
    .create-route .form {
        width: 250px;
    }
}

@media only screen and (max-width: 500px) {
    .create-route {
        flex-direction: column;
    }

    .create-route .form .closed {
        width: calc(100% - 20px);
    }

    .create-route .form .opened {
        width: 100%;
    }

    .create-route .form .input-container {
        width: 100%;
    }
}