.login-page {
    height: 100vh;
    display: flex;

    .section-1 {
        background-image: url('https://takeairdi.com/wp-content/uploads/2024/01/J3500_night-2-1920x0-c-default.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 60%;

        .logo-container {
            margin-top: 20px;
            margin-left: 40px;

            .logo {
                width: 130px;
                height: 45px;
            }
        }

    }

    .section-2 {
        width: 40%;
        background-color: #94A3B8;

        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                border-bottom: 2px solid black;
                width: 40%;
                margin-bottom: 30px;
                padding-bottom: 6px;
            }

            .input {
                background-color: #FFEDD5;
                margin-top: 10px;
                padding: 5px 5px;
                border-radius: 4px;
                border: 2px solid grey;
                font-size: 16px;

                &:focus {
                    outline-width: 0;
                    border-color: black;
                }
            }

            .error-message {
                color: rgb(188, 25, 25);
                padding-left: 2px;
                font-size: 15px;
            }

            .password-container {
                position: relative;

                .icon {
                    position: absolute;
                    cursor: pointer;
                    color: grey;
                    transform: translate(-30px, 14px);
                }

            }

            .forget-div {
                margin-top: 3px;
                width: 100%;
                display: flex;
                justify-content: flex-end;

                div {
                    color: white;
                    font-size: 14px;
                    width: fit-content;
                    cursor: pointer;
                }
            }

            .login-btn {
                margin-top: 20px;
                padding: 7px 35px;
                background-color: #e67c30;
                border: none;
                border-radius: 4px;
                font-size: 18px;

                &:hover {
                    cursor: pointer;
                    background-color: #ff7b06;
                }
            }
        }
    }

}

@media only screen and (max-width: 426px) {
    .login-page {
        flex-direction: column;

        .section-1 {
            width: 100%;
            height: 35vh;

            .logo-container {
                .logo {
                    width: 95px;
                    height: 30px;
                }
            }
        }

        .section-2 {
            width: 100%;
            height: 65vh;
        }
    }
}