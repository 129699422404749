.passenger-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.passenger-list .passenger-card {
    box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        transform: translateX(20px);
    }
}

.passenger-list .passenger-card .card-info {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
}

.passenger-list .passenger-card .arrow-container {
    background-color: #ff7b06;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 15px;
    border-radius: 0 6px 6px 0;

}

.passenger-list .flex-col {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.passenger-list .btn {
    margin-top: 10px;
    width: fit-content;
    padding: 9px 20px;
    background-color: #e67c30;
    border: none;
    border-radius: 4px;
    font-size: 16px;

    &:hover {
        cursor: pointer;
        background-color: #ff7b06;
    }
}