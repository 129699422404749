.global-settings {
    padding: 22px 10px 10px 10px;
    display: flex;
    gap: 10px;
}

.global-settings .tabs {
    background-color: #94A3B8;
    padding: 10px 15px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: fit-content;
}

.global-settings .tabs .title {
    font-size: 18px;
    font-weight: 600;
}

.global-settings .tabs .tab {
    padding: 10px 0;
    width: 150px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
}

.global-settings .tabs .active-tab {
    background-color: rgba(203, 213, 225, 1);
}

.global-settings .tabs .non-active-tab:hover {
    background-color: rgba(191, 191, 191, 0.436);
}

.global-settings .content {
    width: calc(100% - 200px);
}

@media only screen and (max-width: 925px) {
    .global-settings {
        flex-direction: column;
    }

    .global-settings .tabs {
        width: fit-content;
    }

    .global-settings .content {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

    /* .global-settings .tabs .tab {
        width: fit-content;
        padding: 10px 20px;
    } */
}