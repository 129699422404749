.sidebar {
    background-color: #18181B;
    width: 64px;
    height: calc(100% - 64px);
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    padding: 32px 0;
    border-radius: 0 15px 15px 0;
    z-index: 2;
}

.sidebar svg {
    width: 36px;
    height: 36px;
}

.mobile-sidebar-icon {
    position: fixed;
    padding: 5px 0;
    padding-top: 7px;
    background-color: #355C7D;
    width: 100vw;
    text-align: right;
    display: none;
    z-index: 1;
}

.mobile-sidebar-icon svg {
    margin-right: 20px;
}

@media only screen and (max-width: 768px) {

    .sidebar {
        background-color: #2A363B;
        width: 48px;
        height: calc(100% - 100px);
        gap: 25px;
        margin-top: 36px;
        transition: transform 0.3s ease;
    }

    .sidebar svg {
        width: 28px;
        height: 28px;
    }

    .opened-sidebar {
        transform: translateX(0);
    }

    .closed-sidebar {
        transform: translateX(-100%);
    }

    .mobile-sidebar-icon {
        display: block;
    }
}

@media only screen and (max-height: 550px) {
    .sidebar {
        overflow-y: auto;
    }
}