.tickets-counter {
    margin-bottom: 20px;
}

.tickets-counter .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.tickets-counter .flex {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tickets-counter .flex .label {
    font-weight: bold;
}

.tickets-counter .flex .quantity-container {
    display: flex;
    align-items: center;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 6px;
}

.tickets-counter .flex .q-btn {
    padding: 4px 10px;
    cursor: pointer;

    &:hover {
        background-color: rgb(222, 222, 222);
    }
}

.tickets-counter .flex .quantity {
    padding: 4px 20px;
    border-left: 2px solid rgb(180, 180, 180);
    border-right: 2px solid rgb(180, 180, 180);
    font-size: 18px;
}

.tickets-counter .seperator {
    border-bottom: 1px solid rgb(180, 180, 180);
    margin: 20px 0;
}