.ticket-validation {
    background-color: #94A3B8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    border-radius: 15px 0 0 15px;

    .title {
        font-size: 36px;
        font-weight: 700;
        color: #020617;
        padding: 10px;
        padding-top: 30px;
        text-align: center;
    }

    .content {
        background-color: white;
        border-radius: 15px;
        margin: 10px 10px;
        padding: 10px 10px 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        .search-by {
            display: flex;
            gap: 40px;

            div {
                font-size: 20px;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
            }

            .active {
                padding-bottom: 3px;
                border-bottom: 2px solid black;
            }

            .active:first-child {
                width: 70px;
            }

            .active:last-child {
                width: 120px;
            }

            .non-active:hover {
                color: #94A3B8;
            }
        }

        .form {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            overflow-x: hidden;

            .input-container {
                width: 340px;
            }

            .input-flex {
                display: flex;
                gap: 1px;

                .input {
                    flex: 1;
                }
            }

            .input {
                padding: 7px 10px;
                border-radius: 4px;
                border: 2px solid grey;
                background-color: #FFEDD5;
                font-size: 16px;

                &:focus {
                    outline-width: 0;
                    border-color: black;
                }
            }

            .input-full {
                width: calc(340px - 24px);
            }

            .select {
                cursor: pointer;
                padding: 7px 10px;
                border-radius: 4px;
                border: 2px solid grey;
                background-color: #FFEDD5;
                font-size: 16px;
                width: 100%;

                &:focus {
                    outline-width: 0;
                    border-color: black;
                }
            }

            .code-select {
                cursor: pointer;
                padding: 7px 4px;
                border-radius: 4px;
                border: 2px solid grey;
                background-color: #FFEDD5;
                font-size: 16px;
                width: 80px;

                &:focus {
                    outline-width: 0;
                    border-color: black;
                }
            }

            .color-grey {
                color: grey;
            }

            .error {
                color: rgb(188, 25, 25);
                font-size: 15px;
                padding-top: 2px;
            }

            .btn {
                margin-top: 20px;
                padding: 9px 0;
                background-color: #e67c30;
                border: none;
                border-radius: 4px;
                font-size: 16px;
                /* width: fit-content; */

                &:hover {
                    cursor: pointer;
                    background-color: #ff7b06;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .ticket-validation .content .form {
        width: 60%;
    }

    .ticket-validation .content .form .input-container {
        width: 100%;
    }

    .ticket-validation .content .form .input {
        width: calc(100% - 24px);
    }

    .ticket-validation .content .form .select {
        width: 100%;
    }
}