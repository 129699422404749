.svg-container {
    width: 64px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 0 0 15px;
}

.bg-active {
    background-color: #E67C30;
}

@media only screen and (max-width: 768px) {
    .svg-container {
        width: 48px;
        height: 38px;
        border-radius: 13px 0 0 13px;
    }
}