.general-setting {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: fit-content;
}

.general-setting .input-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.general-setting .label {
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
}

.general-setting .input {
    padding: 7px 10px;
    border-radius: 4px;
    border: 2px solid grey;
    background-color: #FFEDD5;
    font-size: 16px;
    width: 200px;

    &:focus {
        outline-width: 0;
        border-color: black;
    }
}

.general-setting .error {
    color: rgb(188, 25, 25);
    font-size: 15px;
}

.general-setting .btn {
    /* margin-top: 20px; */
    padding: 9px 0;
    background-color: #e67c30;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    /* width: fit-content; */

    &:hover {
        cursor: pointer;
        background-color: #ff7b06;
    }
}